import React, { useState, useEffect } from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";
import trofeow from "../img/trofeow.png";

const Cartonfinal = ({
  numeros,
  nombre,
  photo,
  id,
  idcarton,
  mensaje,
  sacada,
  figuras,
  bolitas,
}) => {
  let nomb = nombre;
  var primerNombre = "";

  for (let i = 0; i < nomb.length; i++) {
    if (nomb[i] === " ") {
      i = nomb.length;
    } else {
      primerNombre += nomb[i];
    }
  }

  const marcarresultados = () => {
    for (let i = 0; i < sacada.length; i++) {
      revisarcarton(sacada[i]);
    }
  };

  const marcarblanco = () => {
    for (let i = 0; i < bolitas.length; i++) {
      revisarcarton1(bolitas[i]);
    }
  };

  const revisarcarton = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 16) {
      chequearb(bolitanueva);
    }
    if (bolitanueva > 15 && bolitanueva < 31) {
      chequeari(bolitanueva);
    }
    if (bolitanueva > 30 && bolitanueva < 46) {
      chequearn(bolitanueva);
    }
    if (bolitanueva > 45 && bolitanueva < 61) {
      chequearg(bolitanueva);
    }
    if (bolitanueva > 60 && bolitanueva < 76) {
      chequearo(bolitanueva);
    }
  };

  const revisarcarton1 = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 16) {
      chequearb1(bolitanueva);
    }
    if (bolitanueva > 15 && bolitanueva < 31) {
      chequeari1(bolitanueva);
    }
    if (bolitanueva > 30 && bolitanueva < 46) {
      chequearn1(bolitanueva);
    }
    if (bolitanueva > 45 && bolitanueva < 61) {
      chequearg1(bolitanueva);
    }
    if (bolitanueva > 60 && bolitanueva < 76) {
      chequearo1(bolitanueva);
    }
  };

  const chequearb = (bolita) => {
    if (numeros.b1 == bolita) {
      document.getElementById(`carto${id}b1`).className = cambiacolor("b1");
    }
    if (numeros.b2 == bolita) {
      document.getElementById(`carto${id}b2`).className = cambiacolor("b2");
    }
    if (numeros.b3 == bolita) {
      document.getElementById(`carto${id}b3`).className = cambiacolor("b3");
    }
    if (numeros.b4 == bolita) {
      document.getElementById(`carto${id}b4`).className = cambiacolor("b4");
    }
    if (numeros.b5 == bolita) {
      document.getElementById(`carto${id}b5`).className = cambiacolor("b5");
    }
  };

  const chequearb1 = (bolita) => {
    if (numeros.b1 == bolita) {
      document.getElementById(`carto${id}b1`).className = "";
    }
    if (numeros.b2 == bolita) {
      document.getElementById(`carto${id}b2`).className = "";
    }
    if (numeros.b3 == bolita) {
      document.getElementById(`carto${id}b3`).className = "";
    }
    if (numeros.b4 == bolita) {
      document.getElementById(`carto${id}b4`).className = "";
    }
    if (numeros.b5 == bolita) {
      document.getElementById(`carto${id}b5`).className = "";
    }
  };

  const chequeari = (bolita) => {
    if (numeros.i1 == bolita) {
      document.getElementById(`carto${id}i1`).className = cambiacolor("i1");
    }
    if (numeros.i2 == bolita) {
      document.getElementById(`carto${id}i2`).className = cambiacolor("i2");
    }
    if (numeros.i3 == bolita) {
      document.getElementById(`carto${id}i3`).className = cambiacolor("i3");
    }
    if (numeros.i4 == bolita) {
      document.getElementById(`carto${id}i4`).className = cambiacolor("i4");
    }
    if (numeros.i5 == bolita) {
      document.getElementById(`carto${id}i5`).className = cambiacolor("i5");
    }
  };

  const chequeari1 = (bolita) => {
    if (numeros.i1 == bolita) {
      document.getElementById(`carto${id}i1`).className = "";
    }
    if (numeros.i2 == bolita) {
      document.getElementById(`carto${id}i2`).className = "";
    }
    if (numeros.i3 == bolita) {
      document.getElementById(`carto${id}i3`).className = "";
    }
    if (numeros.i4 == bolita) {
      document.getElementById(`carto${id}i4`).className = "";
    }
    if (numeros.i5 == bolita) {
      document.getElementById(`carto${id}i5`).className = "";
    }
  };

  const chequearn = (bolita) => {
    if (numeros.n1 == bolita) {
      document.getElementById(`carto${id}n1`).className = cambiacolor("n1");
    }
    if (numeros.n2 == bolita) {
      document.getElementById(`carto${id}n2`).className = cambiacolor("n2");
    }

    if (numeros.n4 == bolita) {
      document.getElementById(`carto${id}n4`).className = cambiacolor("n4");
    }
    if (numeros.n5 == bolita) {
      document.getElementById(`carto${id}n5`).className = cambiacolor("n5");
    }
  };

  const chequearn1 = (bolita) => {
    if (numeros.n1 == bolita) {
      document.getElementById(`carto${id}n1`).className = "";
    }
    if (numeros.n2 == bolita) {
      document.getElementById(`carto${id}n2`).className = "";
    }

    if (numeros.n4 == bolita) {
      document.getElementById(`carto${id}n4`).className = "";
    }
    if (numeros.n5 == bolita) {
      document.getElementById(`carto${id}n5`).className = "";
    }
  };

  const chequearg = (bolita) => {
    if (numeros.g1 == bolita) {
      document.getElementById(`carto${id}g1`).className = cambiacolor("g1");
    }
    if (numeros.g2 == bolita) {
      document.getElementById(`carto${id}g2`).className = cambiacolor("g2");
    }
    if (numeros.g3 == bolita) {
      document.getElementById(`carto${id}g3`).className = cambiacolor("g3");
    }
    if (numeros.g4 == bolita) {
      document.getElementById(`carto${id}g4`).className = cambiacolor("g4");
    }
    if (numeros.g5 == bolita) {
      document.getElementById(`carto${id}g5`).className = cambiacolor("g5");
    }
  };

  const chequearg1 = (bolita) => {
    if (numeros.g1 == bolita) {
      document.getElementById(`carto${id}g1`).className = "";
    }
    if (numeros.g2 == bolita) {
      document.getElementById(`carto${id}g2`).className = "";
    }
    if (numeros.g3 == bolita) {
      document.getElementById(`carto${id}g3`).className = "";
    }
    if (numeros.g4 == bolita) {
      document.getElementById(`carto${id}g4`).className = "";
    }
    if (numeros.g5 == bolita) {
      document.getElementById(`carto${id}g5`).className = "";
    }
  };

  const chequearo = (bolita) => {
    if (numeros.o1 == bolita) {
      document.getElementById(`carto${id}o1`).className = cambiacolor("o1");
    }
    if (numeros.o2 == bolita) {
      document.getElementById(`carto${id}o2`).className = cambiacolor("o2");
    }
    if (numeros.o3 == bolita) {
      document.getElementById(`carto${id}o3`).className = cambiacolor("o3");
    }
    if (numeros.o4 == bolita) {
      document.getElementById(`carto${id}o4`).className = cambiacolor("o4");
    }
    if (numeros.o5 == bolita) {
      document.getElementById(`carto${id}o5`).className = cambiacolor("o5");
    }
  };

  const chequearo1 = (bolita) => {
    if (numeros.o1 == bolita) {
      document.getElementById(`carto${id}o1`).className = "";
    }
    if (numeros.o2 == bolita) {
      document.getElementById(`carto${id}o2`).className = "";
    }
    if (numeros.o3 == bolita) {
      document.getElementById(`carto${id}o3`).className = "";
    }
    if (numeros.o4 == bolita) {
      document.getElementById(`carto${id}o4`).className = "";
    }
    if (numeros.o5 == bolita) {
      document.getElementById(`carto${id}o5`).className = "";
    }
  };

  const cambiacolor = (casilla) => {
    for (let i = 0; i < figuras.length; i++) {
      if (figuras[i] == casilla) {
        return "marcador3";
      }
    }
    return "marcador2";
  };

  const chequearresultados = () => {
    var total = figuras.length;
    var totalacertado = 0;
    for (let i = 0; i < figuras.length; i++) {
      if (figuras[i] == "b1") {
        totalacertado = totalacertado + salio(numeros.b1);
      }
      if (figuras[i] == "b2") {
        totalacertado = totalacertado + salio(numeros.b2);
      }
      if (figuras[i] == "b3") {
        totalacertado = totalacertado + salio(numeros.b3);
      }
      if (figuras[i] == "b4") {
        totalacertado = totalacertado + salio(numeros.b4);
      }
      if (figuras[i] == "b5") {
        totalacertado = totalacertado + salio(numeros.b5);
      }
      if (figuras[i] == "i1") {
        totalacertado = totalacertado + salio(numeros.i1);
      }
      if (figuras[i] == "i2") {
        totalacertado = totalacertado + salio(numeros.i2);
      }
      if (figuras[i] == "i3") {
        totalacertado = totalacertado + salio(numeros.i3);
      }
      if (figuras[i] == "i4") {
        totalacertado = totalacertado + salio(numeros.i4);
      }
      if (figuras[i] == "i5") {
        totalacertado = totalacertado + salio(numeros.i5);
      }
      if (figuras[i] == "n1") {
        totalacertado = totalacertado + salio(numeros.n1);
      }
      if (figuras[i] == "n2") {
        totalacertado = totalacertado + salio(numeros.n2);
      }
      if (figuras[i] == "n4") {
        totalacertado = totalacertado + salio(numeros.n4);
      }
      if (figuras[i] == "n5") {
        totalacertado = totalacertado + salio(numeros.n5);
      }
      if (figuras[i] == "g1") {
        totalacertado = totalacertado + salio(numeros.g1);
      }
      if (figuras[i] == "g2") {
        totalacertado = totalacertado + salio(numeros.g2);
      }
      if (figuras[i] == "g3") {
        totalacertado = totalacertado + salio(numeros.g3);
      }
      if (figuras[i] == "g4") {
        totalacertado = totalacertado + salio(numeros.g4);
      }
      if (figuras[i] == "g5") {
        totalacertado = totalacertado + salio(numeros.g5);
      }
      if (figuras[i] == "o1") {
        totalacertado = totalacertado + salio(numeros.o1);
      }
      if (figuras[i] == "o2") {
        totalacertado = totalacertado + salio(numeros.o2);
      }
      if (figuras[i] == "o3") {
        totalacertado = totalacertado + salio(numeros.o3);
      }
      if (figuras[i] == "o4") {
        totalacertado = totalacertado + salio(numeros.o4);
      }
      if (figuras[i] == "o5") {
        totalacertado = totalacertado + salio(numeros.o5);
      }
    }
    if (totalacertado == total) {
      document.getElementById(`boton${id}`).innerHTML = "";
      mensaje("Felicidades", "save");
    } else {
      mensaje("No tienes BINGO", "error");
    }
  };

  const salio = (numero) => {
    for (let i = 0; i < sacada.length; i++) {
      if (numero == sacada[i]) {
        return 1;
      }
    }
    return 0;
  };

  useEffect(() => {
    marcarresultados();
    //console.log(sacada);
  }, [sacada]);

  useEffect(() => {
    marcarblanco();
    //console.log(bolitas);
  }, [bolitas]);
  //console.log(numeros.estatus);

  return (
    <>
      <div className="flex flex-col m-4">
        <span className="h-8 flex flex-row bg-gradient-to-tr from-blue-400 to-blue-900 rounded-t-xl text-white">
          <img
            className="w-10 h-10 mt-[-10px] rounded-full border-2 border-blue-500"
            src={`${photo}`}
            alt=""
          />
          <span className="ml-2 mt-1 text-white text-sm font-semibold">
            {primerNombre}
          </span>
          {idcarton.includes(id) == true ? (
            <>
              <img
                className="mt-[-20px] ml-4"
                src={trofeow}
                width="40px"
                height="60px"
              />
            </>
          ) : (
            <></>
          )}
        </span>

        <table id="bingo-table">
          <tr>
            <th>
              <span class="encabezado1">B</span>
            </th>
            <th>
              <span class="encabezado1">I</span>
            </th>
            <th>
              <span class="encabezado1">N</span>
            </th>
            <th>
              <span class="encabezado1">G</span>
            </th>
            <th>
              <span class="encabezado1">O</span>
            </th>
          </tr>
          <tr>
            <td id={`carto${id}b1`} class="">
              {numeros.b1}
            </td>
            <td id={`carto${id}i1`} class="">
              {numeros.i1}
            </td>
            <td id={`carto${id}n1`} class="">
              {numeros.n1}
            </td>
            <td id={`carto${id}g1`} class="">
              {numeros.g1}
            </td>
            <td id={`carto${id}o1`} class="">
              {numeros.o1}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b2`} class="">
              {numeros.b2}
            </td>
            <td id={`carto${id}i2`} class="">
              {numeros.i2}
            </td>
            <td id={`carto${id}n2`} class="">
              {numeros.n2}
            </td>
            <td id={`carto${id}g2`} class="">
              {numeros.g2}
            </td>
            <td id={`carto${id}o2`} class="">
              {numeros.o2}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b3`} class="">
              {numeros.b3}
            </td>
            <td id={`carto${id}i3`} class="">
              {numeros.i3}
            </td>
            <td class="marcador3 ">
              <svg
                class="w-6 h-6 text-yellow-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
              </svg>
            </td>
            <td id={`carto${id}g3`} class="">
              {numeros.g3}
            </td>
            <td id={`carto${id}o3`} class="">
              {numeros.o3}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b4`} class="">
              {numeros.b4}
            </td>
            <td id={`carto${id}i4`} class="">
              {numeros.i4}
            </td>
            <td id={`carto${id}n4`} class="">
              {numeros.n4}
            </td>
            <td id={`carto${id}g4`} class="">
              {numeros.g4}
            </td>
            <td id={`carto${id}o4`} class="">
              {numeros.o4}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b5`} class="">
              {numeros.b5}
            </td>
            <td id={`carto${id}i5`} class="">
              {numeros.i5}
            </td>
            <td id={`carto${id}n5`} class="">
              {numeros.n5}
            </td>
            <td id={`carto${id}g5`} class="">
              {numeros.g5}
            </td>
            <td id={`carto${id}o5`} class="">
              {numeros.o5}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default Cartonfinal;
